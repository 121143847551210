<template>
  <div 
    class="recognition-card" 
    :class="{'recog-selected': aiRunOn === 'aibox' &&  aiMode === data.ai && data.id === taskId }" 
    @click="viewTask(data.id)"
  >
    <div class="title">
      <img :src="taskIcon" alt="">
      <div class="name">{{ taskTitle }}</div>
    </div>
    <div v-if="data.id === -99" class="status">{{ $t('ai_task_add') }}</div>
    <div v-else-if="aiRunOn === 'aibox' && isEdit && data.id >= 0 && data.id === taskId" class="status">{{ $t('ai_task_edit') }}</div>
    <div v-else-if="data.status === 0" class="status">{{ $t(`ai_status_${data.status}`) }}</div>
    <div v-else class="status error">{{ $t(`ai_status_${data.status}`) }}</div>
    <div class="item">
      <div class="label">{{ $t('ai_box')/*辨識盒*/ }}</div>
      <div class="value">{{ data.aiboxName }}</div>
    </div>
    <div class="item">
      <div class="label">{{ $t('ai_updated_time')/*更新時間*/ }}</div>
      <div 
        class="value time" 
        :class="{ orange: data.timeSinceLastUpdated > 30 && data.timeSinceLastUpdated <= 60, red: data.timeSinceLastUpdated > 60 }">
        {{ timeAgo(data.updatedTime, data.timeSinceLastUpdated) }}
      </div>
      <div v-if="aiRunOn === 'aibox' && data.id === taskId" class="icons">
        <div v-if="!isEdit" class="icon" @click.stop="goEditMode">
          <img src="@/assets/icons/pen.svg" alt="">
        </div>
        <div class="icon" @click.stop="handleDelete"><img src="@/assets/icons/TrashCan.svg" alt=""></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { diffSecs, timeAgo } from '@/utils/lib.js'

export default {
  name: 'TaskCardAibox',
  props: [ 'data' ],
  data() {
    return {}
  },
  computed: {
    ...mapState(['permissionV2']),
    ...mapState('aibox', ['aiRunOn', 'aiMode', 'isEdit', 'taskId', 'aiBoxTasks']),
    ...mapGetters('aibox', ['deviceName', 'aiBoxTask']),
    taskIcon() {
      if (this.data.ai === 'or') return require('@/assets/icons/OR.svg')
      else if (this.data.ai === 'lpr') return require('@/assets/icons/LPR.svg')
      else return ''
    },
    taskTitle() {
      if (this.data.ai === 'or') return this.$t('ai_object_recognition')/*物件辨識*/
      else if (this.data.ai === 'lpr') return this.$t('ai_license_plate_recognition')/*車牌辨識*/
      else return ''
    },
  },
  methods: {
    ...mapMutations('aibox', ['updateIsEdit', 'updateAiMode', 'updateAiRunOn', 'updateTaskId', 
      'updateShowAiboxPortal', 'updateParamAiboxPortal', 'updateOrigAiBoxTask']),
    ...mapActions('aibox', ['getAiBoxes']),
    diffSecs,
    timeAgo,
    getTaskName() {
      if (this.data.ai === 'or') return this.$t('ai_object_recognition')/*物件辨識*/
      else if (this.data.ai === 'fr') return this.$t('ai_person_recognition')/*人物辨識*/
      else ''
    },
    getTaskIcon() {
      if (this.data.ai === 'or') return require('@/assets/icons/OR.svg')
      else if (this.data.ai === 'fr') return require('@/assets/icons/FR.svg')
      else ''
    },
    viewTask(taskId) {
      // 若是編輯模式下，切換辨識任務時，需先挑窗詢問是否要取消編輯
      if (this.isEdit && this.taskId !== taskId) {
        const payload = {
          aiRunOn: 'aibox',
          aiMode: this.data.ai,
          taskId: taskId,
        }
        this.updateParamAiboxPortal({ info: 'cancelEdit', action: 'switchTask', payload })
        this.updateShowAiboxPortal(true)
      } else {
        this.updateAiRunOn('aibox')
        this.updateAiMode(this.data.ai)
        this.updateTaskId(taskId)
        this.getAiBoxes() // 取得辨識盒資源
      }
    },
    goEditMode() {
      this.updateIsEdit(true)
      this.updateOrigAiBoxTask(this.aiBoxTask) // 儲存原始辨識任務資料，用來判斷是否有修改
    },
    handleDelete() {
      this.updateParamAiboxPortal({ info: 'deleteTask' })
      this.updateShowAiboxPortal(true)
    },
  }
}
</script>

<style lang="scss" scoped>
.recognition-card {
  background: #39425D;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 8px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  cursor: pointer;
}

.recog-selected {
  background: #4A5C78;
  border: 3px solid #FFE99F;
}

.title {
  display: flex;
  align-items: center;
  width: 60%;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

.title .name {
  // width: 60%;
}

.status {
  position: absolute;
  top: 12px;
  right: 0;
  max-width: 150px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 6px 12px;
  background: #6E7D93;
  border-radius: 8px 0px 0px 8px;
}

.error {
  background: #F94144;
}

.item {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 4px;
  position: relative;
}

.item .label {
  width: 40%;
}

.item .value {
  width: 60%;
  word-break: break-all;
}

.time {
  color: #8CC24D;
}

.orange {
  color: #D8A848;
}

.red {
  color: #F94144;
}

.icons {
  display: flex;
  position: absolute;
  right: 0px;
  top: -3px;
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.icon:hover {
  background: #FFFFFF33;
}

.icon img {
  width: 20px;
  height: 20px;
}

.icons .icon:first-of-type {
  margin-right: 4px;
}
</style>